import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import SEO from "../../components/seo";
import Layout from "../../components/layouts/layout";
import FormularioBasico from "../../components/widgets/formularios/FormularioBasico";

export default function Refacciones(props){

    const parameters = props.location.search;

    return(
        <Layout selectedMenuItem="refacciones" parameters={parameters}>
            <SEO
                title="Grupo Rivero - Refacciones Chevrolet Monterrey - Agencia Chevrolet"
                description="Refacciones Chevrolet Monterrey - Agencia Chevrolet refacciones - Refacciones GM - Refacciones Buick, Refacciones Chevrolet Originales - Autos Seminuevos Agencia"
                keywords="Taller Chevrolet, taller de servicio automotriz, seguros chevrolet, servicio chevrolet"
                path="/refacciones"
            />
            <Container>
                <div className="bg-white">
                    <Row>
                        <Col lg={8} md={8} sm={12}>
                            {/* <Image fileName="banner_taller_refacciones" alt="Refacciones"/> */}
                            <h1 className="p-4 bg-light">Refacciones Nissan</h1>
                            <div className="text-justify">
                                <div className="p-4">
                                    <p>
                                    ¡Que habido primo! ¿Estás buscando refacciones para tu vehículo?
                                    </p>
                                    <p>
                                    En Rivero Nissan contamos con una gran variedad de inventario, estamos seguros de tener las piezas adecuadas para tu automóvil. Ya sea que necesites repuestos para el motor, frenos, suspensión, o cualquier otra parte, ¡podemos ayudarte!
                                    </p>
                                    <p>
                                    Nuestro equipo de expertos está listo para asesorarte y brindarte el mejor servicio. Estamos aquí para asegurarnos de que obtengas las refacciones de alta calidad que necesitas. 
                                    </p>
                                    <p>
                                    No pierdas tiempo buscando en diferentes lugares. Ven a nuestro departamento de refacciones y encuentra todo lo que necesitas en un solo lugar. Porque en Rivero ¡Todos somos primos!
                                    </p>

                                    {/* <h2>
                                        Encuentra todas las refacciones originales Chevrolet en
                                        Grupo Rivero{" "}
                                    </h2>

                                    <br />

                                    <h3>
                                        Somos un distribuidor autorizado de GM Parts y AC Delco.
                                    </h3>

                                    <p>
                                        <strong>Cualquier refacción de la línea de GM Parts</strong>{" "}
                                        puede ser solicitada y requerida bajo pedido, sin
                                        restricción de modelo o tipo de vehículo de la familia de
                                        GM, brindándote siempre los mejores tiempos de entrega de
                                        toda la zona norte de México.
                                    </p>

                                    <p>
                                        Recuerda siempre usar productos certificados de ACDelco y GM
                                        Parts para mantener la vigencia de tu garantía en tu
                                        vehículo Chevrolet o GM.
                                    </p>

                                    <p>
                                        ¿No contamos con la refacción que necesitas? Llena nuestro
                                        formulario de contacto para darle un seguimiento puntual de
                                        la pieza que te hace falta y resolverte a la brevedad. En
                                        Grupo Rivero te apoyamos de manera transparente en el
                                        cuidado y mantenimiento de tu auto.
                                    </p> */}
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={4} sm={12} className="p-4">
                            <h3>Formulario de Contacto</h3>
                            <FormularioBasico pagina="refacciones" btnTitulo="Contactar" props={props}>
                                <input
                                    type="hidden"
                                    name="subject"
                                    id="subject"
                                    value="Refacciones"
                                    hidden
                                />
                            </FormularioBasico>
                        </Col>
                    </Row>         
                </div>
            </Container>
        </Layout>
    )

}